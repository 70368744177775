import { Application } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

application.consumer = consumer

document.addEventListener("turbo:before-cache", function () {
  application.controllers.forEach(function (controller) {
    try {
      if (typeof controller.disconnect === "function") {
        controller.disconnect()
      }
    } catch (_) { }
  })
})


export { application }

/*
  Image preview

  Load a preview of a loaded input file
*/
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["preview", "container"]

  load(e) {
    let fi = this.element.querySelector("input[type=file]")
    let file = fi.files[0]

    if (file.type === "image/jpeg") this.createPreviewFromExif(file)
    else this.createPreview(file)
  }

  createPreview(file) {
    let img = new Image()
    img.src = URL.createObjectURL(file)
    img.classList.add("max-h-full")
    this.previewTarget.replaceChildren(img)
    this.containerTarget.classList.remove("hidden")
  }

  createPreviewFromExif(file) {
    let reader = new FileReader()
    reader.onload = (e) => {
      let array = new Uint8Array(e.target.result),
        start,
        end
      for (let i = 2; i < array.length; i++) {
        if (array[i] === 0xff) {
          if (!start) {
            if (array[i + 1] === 0xd8) start = i
          } else {
            if (array[i + 1] === 0xd9) {
              end = i + 2
              break
            }
          }
        }
      }

      if (start && end) {
        let img = new Image()
        img.src = URL.createObjectURL(new Blob([array.subarray(start, end)], { type: "image/jpeg" }))
        this.previewTarget.replaceChildren(img)
      } else {
        this.createPreview(file)
      }
    }

    reader.readAsArrayBuffer(file.slice(0, 80000))
  }
}

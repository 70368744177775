import ApplicationController from "../../../javascript/controllers/application_controller"

export default class extends ApplicationController {
  static targets = ["emptySection", "defaultState", "openedState", "linkInputWrapper", "imageSetSection", "imagePreview", "fileName"]
  static values = {
    customChange: String,
    cssTarget: String,
    recordType: String,
    recordId: String,
  }

  connect() {
    super.connect()
    // To change when Upload Image is enabled
    this.uploadEnabled = false

    this.linkInput = this.linkInputWrapperTarget.querySelector("input")
    // this.uploadInput = this.defaultStateTarget.querySelector("input")
  }

  openLink() {
    this.hide(this.defaultStateTarget)
    this.show(this.openedStateTarget)
    this.show(this.linkInputWrapperTarget)
  }

  closeLink() {
    this.show(this.defaultStateTarget)
    this.hide(this.openedStateTarget)
    this.hide(this.linkInputWrapperTarget)
  }

  uploadImageChanged(event) {
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size > 5000000) {
        document.dispatchEvent(
          new CustomEvent("openAlert", {
            detail: {
              type: "alert",
              text: "Your image must be < 5MB.",
              close: true,
            },
          })
        )
      } else {
        var reader = new FileReader()
        let name = event.currentTarget.dataset.name
        reader.onload = (e) => {
          this.applyChange(e.target.result)
          this.imagePreviewTarget.style["background-image"] = `url('${e.target.result}')`
          this.fileNameTarget.innerText = event.target.files[0].name
          if (this.recordIdValue) {
            // REMOVED -> To Replace if enabled again 
            // this.stimulate("Input#update_uploaded_image", { serializeForm: true }, this.recordTypeValue, this.recordIdValue, name, e.target.result)
          }
          this.show(this.imageSetSectionTarget)
          this.hide(this.emptySectionTarget)
          this.hide(this.linkInputWrapperTarget)
        }
        reader.readAsDataURL(event.target.files[0])
      }
    }
  }

  linkChanged(e) {
    let link = e.target.value
    this.imagePreviewTarget.style["background-image"] = `url('${link}')`
    this.fileNameTarget.innerText = link
    this.show(this.imageSetSectionTarget)
    this.hide(this.emptySectionTarget)
    this.hide(this.linkInputWrapperTarget)
    this.applyChange(link)

    if (this.recordIdValue) {
      this.updateElementField(this.recordTypeValue, this.recordIdValue, this.linkInput.dataset.name, link)
    }
  }

  deleteImage() {
    // Delete Link
    this.imagePreviewTarget.style["background-image"] = ""
    this.applyChange("")
    this.fileNameTarget.innerText = ""
    this.linkInput.value = ""

    this.updateElementField(this.recordTypeValue, this.recordIdValue, this.linkInput.dataset.name, "")

    if (this.uploadEnabled) {
      // REMOVED -> To change if enabled again
      // this.stimulate("Input#remove_uploaded_image", { serializeForm: true }, this.recordTypeValue, this.recordIdValue, this.uploadInput.dataset.name)
    } else {
      this.show(this.linkInputWrapperTarget)
    }
    this.hide(this.imageSetSectionTarget)
    this.show(this.emptySectionTarget)
    this.closeLink()
  }

  applyChange(link) {
    if (this.customChangeValue) {
      this.applyCustomChange(this.customChangeValue, link)
    } else if (this.cssTargetValue) {
      let targets = document.querySelectorAll(this.cssTargetValue)
      if (targets) {
        targets.forEach((target) => {
          if (!link || link.length === 0) {
            target.style["background-image"] = ""
            target.style["background-color"] = "grey"
          } else {
            target.style["background-image"] = `url('${link}')`
            target.style["background-color"] = "transparent"
            target.style["background-size"] = "cover"
            target.style["background-position"] = "center"
          }
        })
      }
    }
  }

  hide(target) {
    target.classList.add("hidden")
  }

  show(target) {
    target.classList.remove("hidden")
  }

  applyCustomChange(custom_change, value) {
    switch (custom_change) {
      case "image-uploaded":
        var target = document.querySelector(this.cssTargetValue)
        if (value == "") {
          // Gray BG
          target.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAkCAYAAAAD3IPhAAAABHNCSVQICAgIfAhkiAAAABl0RVh0U29mdHdhcmUAZ25vbWUtc2NyZWVuc2hvdO8Dvz4AAAAodEVYdENyZWF0aW9uIFRpbWUAbWFyLiAxNCBub3YuIDIwMjMgMTI6MjE6NTiA/suSAAAAP0lEQVRYhe3OsQGAIBAAMWDy31xam9RaXCbInpln/cT5OvBWRspIGSkjZaSMlJEyUkbKSBkpI2WkjJSRMlJGLlfgAsfS/mEcAAAAAElFTkSuQmCC"
        } else {
          target.src = value
        }
        break
      case "new-carousel-slide-uploaded":
        this.element.closest("form").requestSubmit()
        break
      case "video-poster-changed":
        var target = document.querySelector(this.cssTargetValue)
        if (target) {
          let video = target.querySelector('video')
          video.poster = value
        }
        break
      case "scratch-background-changed":
        var target = document.querySelector(this.cssTargetValue)
        if (target) {
          if (!value || value.length === 0) {
            target.style["background-image"] = ""
          } else {
            target.style["background-image"] = `url('${value}')`
            target.style["background-size"] = "cover"
            target.style["background-position"] = "center"
          }
        }
        break
      case "scratch-foreground-changed":
        var target = document.querySelector(this.cssTargetValue)
        target.dataset.foregroundImage = value
        var displayForeground = this.element.closest('form').querySelector('[data-foreground]').dataset.foreground
        document.dispatchEvent(new CustomEvent(`refreshCanvas${this.recordIdValue}`, { detail: { foreground: displayForeground } }))
        break
      default:
        break
    }
  }
}

import { Controller } from "@hotwired/stimulus";
import { initializeCarousels } from "../external/siema_with_dots";

// Connects to data-controller="inapp-partial"
export default class extends Controller {
  // Actions to do everytime the Inapp View is re-rendered
  connect() {
    // Skip init on Multi-Inapp tree page
    if(this.element.closest('.tree-branch') === null){
      let hasCarousel = this.element.querySelector('.carousel')
      if(hasCarousel) {
        initializeCarousels('.carousel')
      }
      setTimeout(() => {
        document.dispatchEvent(new Event("initElementSelection"))
        document.dispatchEvent(new Event("initSortable"))
        document.dispatchEvent(new Event("initVideo"))
        window.dispatchEvent(new Event('resize')) // Re-renders the carousel / fix width
      }, 100)
    }
  }
}

import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
  static targets = ["menu", "panel"]
  static classes = ["hidden"]

  open() {
    this.menuTarget.classList.remove(this.hiddenClass)
    this.panelTargets.forEach((element) => enter(element))
  }

  close() {
    this.panelTargets.forEach((element) =>
      leave(element).then(() => {
        this.menuTarget.classList.add(this.hiddenClass)
      })
    )
  }

  toggle() {
    if (this.menuTarget.classList.contains(this.hiddenClass)) {
      this.open()
    } else {
      this.close()
    }
  }
}

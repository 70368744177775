import ApplicationController from "../../../../javascript/controllers/application_controller";

// Same controller for :
// multi_inapp / navbar_component
// inapp / navbar_component
export default class extends ApplicationController {
  static values = {
    projectId: Number,
    url: String,
  }

  saveAsDraft(e) {
    this.setDraftOnProject(true)
    let button = e.currentTarget
    button.innerText = "Saving..."
    setTimeout(() => {
      button.innerText = "Save as draft"
      this.successAlert("Successfully saved !", false)
    }, 100)
  }

  copyHtml(e) {
    this.setDraftOnProject(false)
    fetch(`${this.urlValue}?pp=skip`, {
      method: "GET",
    })
      .then((response) => {
        return {
          status: response.status,
          html: response.text(),
        }
      })
      .then((data) => {
        if (data.status == 200) {
          data.html.then((html) => {
            let exportedHtml = html.replaceAll('&#39;', "'")
            navigator.clipboard.writeText(exportedHtml)
            document.dispatchEvent(
              new CustomEvent("openAlert", {
                detail: {
                  type: "success",
                  text: "HTML code copied!",
                  close: false,
                },
              })
            )
          })
        }
      })
  }

  setDraftOnProject(value) {
    fetch(this.urlValue, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.head.querySelector(`meta[name="csrf-token"]`)?.getAttribute("content"),
      },
      body: JSON.stringify({
        draft: value
      })
    })
  }
}

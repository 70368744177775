import { Controller } from "@hotwired/stimulus"
import sortable from "html5sortable/dist/html5sortable.es.js"

// Connects to data-controller="editor--multi-slider--reorder"
export default class extends Controller {
  static values = {
    url: String,
  }

  connect() {
    sortable(".sortable-slider", {
      handle: ".handle",
      placeholderClass: "multi-slider-placeholder",
    })

    sortable(".sortable-slider").forEach((sortableEl) => {
      sortableEl.addEventListener("sortupdate", (e) => {
        this.updateSlidesOrder(e.detail.destination.items)
      })
    })
  }

  updateSlidesOrder(items) {
    var slides = []
    items.forEach((item, i) => {
      slides[i] = item.dataset.slideId
    })
    fetch(this.urlValue, {
      method: "PUT",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.head.querySelector(`meta[name="csrf-token"]`)?.getAttribute("content"),
      },
      body: JSON.stringify({
        slides: slides,
      }),
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }
}

import ApplicationController from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static values = {
    recordType: String,
    recordField: String,
    recordId: String,
  }

  updateField(e) {
    this.updateElementField(
      this.recordTypeValue,
      this.recordIdValue,
      this.recordFieldValue,
      e.currentTarget.value
    )
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="account--fonts"
export default class extends Controller {
  static targets = ["fileSection", "name", "link", "text", "error", "button"]

  connect() {
    this.buttonTarget.disabled = true
    this.fileSectionTarget.addEventListener("dragenter", () => {
      this.fileSectionTarget.classList.add("border-indigo-300")
    })
    this.fileSectionTarget.addEventListener("dragleave", () => {
      this.fileSectionTarget.classList.remove("border-indigo-300")
    })
    // Block name if google link
    let link = this.linkTarget.value
    if (link) {
      let url = new URL(link)
      let name = this.getGoogleFontName(url)
      if (name) {
        this.nameTarget.setAttribute("readonly", true)
      }
    }
  }

  fontUploaded(e) {
    let file = e.currentTarget.files[0]
    if (file.name) {
      this.textTarget.innerText = file.name
      this.buttonTarget.disabled = false
    }
  }

  linkChanged(e) {
    let input = e.currentTarget
    let link = input.value
    let valid = this.isValidUrl(link)
    this.nameTarget.removeAttribute("readonly", true)
    if (valid) {
      this.errorTarget.innerText = ""
      this.buttonTarget.disabled = false
      let url = new URL(link)
      let name = this.getGoogleFontName(url)
      if (name) {
        this.nameTarget.setAttribute("readonly", true)
        this.nameTarget.value = name
      }
    } else {
      this.errorTarget.innerText = "The link is not a valid URL."
      this.buttonTarget.disabled = true
    }
  }

  isValidUrl(str) {
    try {
      new URL(str)
    } catch (e) {
      return false
    }
    return true
  }

  getGoogleFontName(url) {
    if (url.hostname === "fonts.google.com") {
      let paths = url.pathname.split("/")
      let name = paths[paths.length - 1]
      return name
    } else if (url.hostname === "fonts.googleapis.com") {
      let fontFamily = url.search.split("&")[0]
      let name = fontFamily.replace("?family=", "")
      return name
    } else {
      return undefined
    }
  }
}

import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

// Connects to data-controller="shared--js-flash"
export default class extends Controller {
  static targets = ["alert", "successIcon", "alertIcon", "warningIcon", "noticeIcon", "text", "close"]

  connect() {
    this.colors = this.styles()
    document.addEventListener("openAlert", this.openAlert.bind(this))
  }

  openAlert(event) {
    if (!(event.detail && event.detail.type)) {
      return
    }
    this.setColors(event.detail.type)
    this.textTarget.innerText = event.detail.text
    if (!event.detail.close) {
      this.closeTarget.classList.add("hidden")
    }
    enter(this.alertTarget)

    setTimeout(() => {
      this.closeAlert()
    }, 3000)
  }

  closeAlert() {
    leave(this.alertTarget)
    setTimeout(() => {
      this.unsetColors()
    }, 110)
  }

  setColors(type) {
    let colors = this.colors[type]
    this.alertTarget.classList.add(colors.backgroundColor)
    this.textTarget.classList.add(colors.textColor)
    this.closeTarget.classList.add(colors.backgroundColor)
    this.closeTarget.classList.add(colors.hoverButtonColor)
    this.closeTarget.querySelector("svg").classList.add(colors.textColor)
    let icon = this[`${type}IconTarget`]
    if (icon) {
      icon.classList.remove("hidden") // Show Icon
    }
  }

  unsetColors() {
    let keys = Object.keys(this.colors)
    keys.forEach((key) => {
      this.alertTarget.classList.remove(this.colors[key].backgroundColor)
      this.textTarget.classList.remove(this.colors[key].textColor)
      this.closeTarget.classList.remove(this.colors[key].backgroundColor)
      this.closeTarget.classList.remove(this.colors[key].hoverButtonColor)
      this.closeTarget.querySelector("svg").classList.remove(this.colors[key].textColor)
      let icon = this[`${key}IconTarget`]
      if (icon) {
        icon.classList.add("hidden") // Hide Icon
      }
    })
    this.closeTarget.classList.remove("hidden")
  }

  styles() {
    return {
      success: {
        backgroundColor: "bg-green-50",
        textColor: "text-green-800",
        hoverButtonColor: "hover:bg-green-100",
      },
      alert: {
        backgroundColor: "bg-red-50",
        textColor: "text-red-800",
        hoverButtonColor: "hover:bg-red-100",
      },
      warning: {
        backgroundColor: "bg-yellow-50",
        textColor: "text-yellow-800",
        hoverButtonColor: "hover:bg-yellow-100",
      },
      notice: {
        backgroundColor: "bg-blue-50",
        textColor: "text-blue-800",
        hoverButtonColor: "hover:bg-blue-100",
      },
    }
  }
}

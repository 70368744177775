import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    target: String,
  }

  updateNumberStars(e) {
    this.container = document.querySelector(this.targetValue)
    let newNumber = Number(e.target.value)
    if (this.container) {
      let oldNumber = this.container.childElementCount

      // Return if limit (1 or 10) is reached
      if (oldNumber === newNumber) {
        return
      }
      newNumber > oldNumber ? this.addStar() : this.removeStar()
    }
  }

  addStar() {
    this.container = document.querySelector(this.targetValue)
    let lastStar = this.container.children[this.container.children.length - 1]
    let newStar = lastStar.cloneNode(true)
    newStar.dataset.id = this.container.children.length + 1
    this.container.appendChild(newStar)
  }

  removeStar() {
    this.container = document.querySelector(this.targetValue)
    let lastStar = this.container.children[this.container.children.length - 1]
    lastStar.remove()
  }
}

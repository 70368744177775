import ApplicationController from "../../../../javascript/controllers/application_controller"

export default class extends ApplicationController {
  static values = {
    recordType: String,
    recordId: String
  }

  connect() {
    super.connect()
    document.addEventListener('videoAsBackgroundChanged', this.toggleVideoAsBackground.bind(this))
  }

  updateVideoLink(e) {
    let value = e.currentTarget.value
    let targetName = e.currentTarget.dataset.target
    let target = document.querySelector(targetName + " source")
    target.src = value

    this.updateElementField(
      this.recordTypeValue,
      this.recordIdValue,
      'video_link',
      value
    )
    target.parentElement.load()
  }

  toggleVideoAsBackground(e) {
    let inputWrapper = this.element.querySelector('#video_not_background_height_input')
    if (inputWrapper) {
      inputWrapper.classList.toggle('hidden')
    }
    let video = document.querySelector(`.video-wrapper-${this.recordIdValue}`)
    if (e.detail.value === "false") {
      video.style.removeProperty('inset')
      video.style['position'] = 'relative'
      video.style['height'] = inputWrapper.querySelector('[name="video_element[height]"]').value
    } else {
      video.style['height'] = '100%'
      video.style['position'] = 'absolute'
      video.style['inset'] = '0'
    }
  }
}

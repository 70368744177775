import ApplicationController from "../../../javascript/controllers/application_controller"

export default class extends ApplicationController {
  static targets = ["valueInput", "unit", "input"]
  static values = {
    customCssChange: String,
    cssTarget: String,
    cssRule: String,
    recordType: String,
    recordField: String,
    recordId: String,
  }

  handleUnitChange(event) {
    this.unitTarget.innerText = event.currentTarget.innerText
    this.updateInput()
  }

  handleValueChange(event) {
    setTimeout(() => {
      this.updateInput()
    }, 150)
  }

  updateInput() {
    let value = (this.valueInputTarget.value + this.unitTarget.innerText).replace(/\s/g, "")
    this.inputTarget.value = value

    this.updateElementField(
      this.recordTypeValue,
      this.recordIdValue,
      this.recordFieldValue,
      value
    )

    // Apply changes
    // If the input change need a custom function
    if (this.customCssChangeValue) {
      this.applyCustomChange(this.customCssChangeValue, value)
    } else {
      if (this.cssTargetValue && this.cssRuleValue) {
        let targets = document.querySelectorAll(this.cssTargetValue)
        if (targets) {
          targets.forEach((target) => {
            this.cssRuleValue.split(" ").forEach((rule) => {
              target.style[rule] = value
            })
          })
        }
      }
    }
  }

  applyCustomChange(custom_change, value) {
    switch (custom_change) {
      case "cross-thickness":
        this.changeCrossThickness(value)
        break
      case "stars":
        this.changeAllStars(value)
        break
      case "stars-border-width":
        this.changeStarsBorderWidth(value)
        break
      case "input-rows":
        this.changeInputRows(value)
        break
      case "carousel-width":
        this.changeCarouselWidth(value)
        break
      case "video-controls-margins":
        this.changeVideoControlsMargins(value)
      case "background-opacity":
        this.changeBackgroundOpacity(value)
        break
      default:
        break
    }
  }

  changeCrossThickness(value) {
    let target = document.querySelector(this.cssTargetValue).querySelector("svg")
    target.setAttribute("stroke-width", value)
  }

  changeAllStars(value) {
    let targets = document.querySelectorAll(this.cssTargetValue)
    targets.forEach((star) => {
      this.cssRuleValue.split(" ").forEach((rule) => {
        star.style[rule] = value
      })
    })
  }

  changeStarsBorderWidth(value) {
    let targets = document.querySelectorAll(this.cssTargetValue)
    targets.forEach((star) => {
      star.setAttribute("stroke-width", value)
    })
  }

  changeInputRows(value) {
    let targets = document.querySelectorAll(this.cssTargetValue)
    targets.forEach((input) => {
      input.setAttribute("rows", value)
    })
  }

  changeCarouselWidth(value) {
    let targets = document.querySelectorAll(this.cssTargetValue)
    targets.forEach((carousel) => {
      carousel.style["width"] = value
    })
    document.dispatchEvent(new Event("rerenderCarousels"))
  }

  changeVideoControlsMargins(value) {
    let target = document.querySelector(this.cssTargetValue)
    let form = this.element.closest('form')
    let positionValue = form.querySelector('[name="video_element[controls_position]"]').value
    target.style[positionValue] = value
  }

  changeBackgroundOpacity(value) {
    let targets = document.querySelectorAll(this.cssTargetValue)
    targets.forEach((wrapper) => {
      wrapper.style["background-color"] = `rgba(0,0,0, ${value})`
    })
  }
}

import ApplicationController from "../../../javascript/controllers/application_controller"

export default class extends ApplicationController {
  static targets = ["circle", "input"]
  static values = {
    cssCustom: String,
    cssTarget: String,
    cssRule: String,
    recordType: String,
    recordField: String,
    recordId: String,
  }

  selectColor(event) {
    let id = event.currentTarget.dataset.colorId
    let value = event.currentTarget.dataset.colorValue

    this.circleTarget.style.backgroundColor = value
    this.inputTarget.value = id

    // Apply changes
    if (this.cssCustomValue) {
      this.applyCustomChange(this.cssCustomValue, value)
    } else {
      if (this.cssTargetValue) {
        let targets = document.querySelectorAll(this.cssTargetValue)
        if (targets) {
          targets.forEach((target) => {
            target.style[this.cssRuleValue] = value
          })
        }
      }
    }

    this.updateElementField(
      this.recordTypeValue,
      this.recordIdValue,
      this.recordFieldValue,
      this.inputTarget.value
    )
  }

  applyCustomChange(custom_change, value) {
    switch (custom_change) {
      case "star-fill-color-changed":
        var targets = document.querySelectorAll(this.cssTargetValue)
        if (targets) {
          targets.forEach((target) => {
            target.setAttribute("fill", value)
          })
        }
        break
      case "carousel-dot-color-changed":
        var target = document.querySelector(`.carousel_wrapper_${this.recordIdValue}`)
        this.setCSSVariable(target, "--bg-color", value)
        break
      case "dark-mode-changed":
        this.refreshView()
        break
      case "dark-mode-changed-slider":
        this.refreshSliderView()
        break
      case "carousel-active-dot-color-changed":
        var target = document.querySelector(`.carousel_wrapper_${this.recordIdValue}`)
        this.setCSSVariable(target, "--active-bg-color", value)
        break
      case "toggle-background-changed":
        var target = document.querySelector(`.toggles-container-${this.recordIdValue}`)
        this.setCSSVariable(target, "--background-color", value)
        break
      case "toggle-circle-changed":
        var target = document.querySelector(`.toggles-container-${this.recordIdValue}`)
        this.setCSSVariable(target, "--circle-color", value)
        break
      case "scratch-foreground-color-changed":
        var target = document.querySelector(this.cssTargetValue)
        target.dataset.foregroundColor = value
        var displayForeground = this.element.closest("form").querySelector("[data-foreground]").dataset.foreground
        document.dispatchEvent(new CustomEvent(`refreshCanvas${this.recordIdValue}`, { detail: { foreground: displayForeground } }))
        break
      default:
        break
    }
  }

  setCSSVariable(target, variable, value) {
    target.style.setProperty(variable, value)
  }

  refreshView() {
    let inappId = this.element.closest("#editor_side_forms").parentElement.dataset.inappId
    fetch(`/inapps/${inappId}`, {
      method: "GET",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.head.querySelector(`meta[name="csrf-token"]`)?.getAttribute("content"),
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }

  refreshSliderView() {
    let projectId = this.element.closest("#editor_side_forms").parentElement.dataset.projectId
    fetch(`/multi_sliders/${projectId}`, {
      method: "GET",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.head.querySelector(`meta[name="csrf-token"]`)?.getAttribute("content"),
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }
}

import ApplicationController from "../../../javascript/controllers/application_controller"

export default class extends ApplicationController {
  static targets = ["circle", "container", "input"]
  static values = {
    state: String,
    eventName: String,
    recordType: String,
    recordField: String,
    recordId: String,
  }

  connect() {
    super.connect()
    if (this.stateValue === "true") {
      this.toggleClasses()
    }
  }

  toggleSwitch() {
    this.toggleClasses()
    // Toggles data state value
    this.stateValue = this.stateValue === "true" ? "false" : "true"
    // Updates input's value
    this.inputTarget.value = this.stateValue

    this.updateElementField(
      this.recordTypeValue,
      this.recordIdValue,
      this.recordFieldValue,
      this.inputTarget.value
    )

    document.dispatchEvent(new CustomEvent(this.eventNameValue, { detail: { value: this.stateValue } }))
  }

  toggleClasses() {
    this.containerTarget.classList.toggle("bg-indigo-600")
    this.containerTarget.classList.toggle("bg-gray-200")
    this.circleTarget.classList.toggle("translate-x-3")
    this.circleTarget.classList.toggle("translate-x-0")
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "message"]

  connect() {
    this.verify(this.inputTarget.value)
  }

  checkCSS(e) {
    let str = e.target.value
    let isValid = this.verify(str)
    this.updateStyleTag(str, isValid)
  }

  updateStyleTag(str, valid = true) {
    if (valid) {
      let tag_id = this.element.dataset.tagId
      let tag = document.getElementById(tag_id)
      if (tag) {
        // Force all rules to be !important
        str = str.replaceAll("!important;", ";")
        str = str.replaceAll(";", "!important;")
        tag.innerHTML = str
      }
    }
  }

  verify(str) {
    const validBrackets = this.verifyBrackets(str)
    //const cssErrors = validate(str)
    const cssErrors = []
    const validCss = cssErrors.length === 0
    let message = ""
    if (!validBrackets) {
      message += "<p> There seems to be a problem with the parentheses '( )' or brackets '{ }'. Please verify before submitting your changes.</p>"
    }

    if (!validCss) {
      cssErrors.forEach((err) => {
        message += "<p>" + err.message + "</p>"
      })
    }
    this.messageTarget.innerHTML = message
    return validBrackets && validCss
  }

  verifyBrackets(str) {
    let stack = []
    let map = {
      "(": ")",
      "{": "}",
    }
    for (let i = 0; i < str.length; i++) {
      // If an opening is found -> add to the array
      if (str[i] === "{" || str[i] === "(") {
        stack.push(str[i])
      }
      // If a closing is found -> remove an element from the array
      if (str[i] === "}" || str[i] === ")") {
        let last = stack.pop()
        // If the popped element from the stack, which is the last opening brace doesn’t match the corresponding closing brace in the map, then return false
        if (str[i] !== map[last]) {
          return false
        }
      }
    }

    if (stack.length !== 0) {
      return false
    }
    return true
  }
}

import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

// Connects to data-controller="editor--add-element"
export default class extends Controller {
  static values = {
    url: String,
    inappId: Number,
  }
  static targets = ["modal"]

  open(e) {
    e.stopPropagation()
    enter(this.modalTarget)

    // Trigger Event to disable hover changes outside the modal
    document.dispatchEvent(new Event("addElementModalOpened"))
  }

  close() {
    leave(this.modalTarget)
    // Trigger Event to re-enable hover changes outside the modal
    document.dispatchEvent(new Event("addElementModalClosed"))
  }

  async addElement(e) {
    let type = e.target.closest("[data-type]").dataset.type
    e.preventDefault()

    let selectedDeviceId = document.querySelector('input[name="selected_device"]').value

    // Then continues to the ADD request
    fetch(this.urlValue, {
      method: "POST",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: type,
        inappId: this.inappIdValue,
        selected_device: selectedDeviceId
      }),
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
      .then(() => {
        this.close()
      })
  }
}

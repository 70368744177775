import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
  static targets = ["dropdown", "icon"]

  toggleDropdown(e) {
    e.stopPropagation()
    this.dropdownTarget.dataset.state === "closed" ? this.open() : this.close()
  }

  open() {
    enter(this.dropdownTarget)
    this.dropdownTarget.dataset.state = "opened"
    if (this.hasIconTarget) {
      this.iconTarget.classList.add("rotate-180")
    }
  }

  close() {
    if (this.dropdownTarget.dataset.state === "opened") {
      leave(this.dropdownTarget)
      this.dropdownTarget.dataset.state = "closed"
    }
    if (this.hasIconTarget) {
      this.iconTarget.classList.remove("rotate-180")
    }
  }
}

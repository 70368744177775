import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ['source']

  copy(event) {
    var elements = this.sourceTargets.map(el => el.innerText);
    var text = elements.join(' \n')
    navigator.clipboard.writeText(text)

    var current = event.currentTarget
    var successText = current.dataset.success
    if (successText) {
      current.innerText = successText
      setTimeout(() => {
        current.innerText = text
      }, 3000)
    }
  }
}

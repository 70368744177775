import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
  static targets = ["content"]

  toggle(e) {
    e.stopPropagation()
    if (this.contentTarget.dataset.state === "closed") {
      this.open()
    } else {
      this.close()
    }
  }

  open() {
    enter(this.contentTarget)
    this.contentTarget.dataset.state = "opened"
  }

  close() {
    leave(this.contentTarget)
    this.contentTarget.dataset.state = "closed"
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    unit: String,
    countdownId: Number
  }

  checkboxChanged(e) {
    let isChecked = e.currentTarget.checked
    let countdown = document.querySelector(`.countdown-${this.countdownIdValue}`)
    let target = countdown.querySelector(`.${this.unitValue}`)
    if(isChecked) {
      target.style['display'] = 'flex'
    } else {
      target.style['display'] = 'none'
    }
    countdown.setAttribute(`data-display-${this.unitValue}`, isChecked)
  }

  labelChanged(e){
    let target = document.querySelector(`.countdown-${this.countdownIdValue} .${this.unitValue} .label`)
    target.innerText = e.currentTarget.value
  }
}

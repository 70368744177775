import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  updatePlaceholder(e) {
    let value = e.target.value
    let targetName = e.target.dataset.target
    let target = document.querySelector(targetName)

    target.placeholder = value
  }

  updateNumberRows(e) {
    let value = e.target.value
    let targetName = e.target.dataset.target
    let target = document.querySelector(targetName)

    target.setAttribute("rows", value)
  }
}

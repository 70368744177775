import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["originInput", "actionInput", "elementIdInput", "elementTypeInput", "informationsText", "searchInput", "submitButton"]
  static values = {
    getTemplatesUrl: String,
    getInappsUrl: String,
    getFileUrl: String,
  }

  connect() {
    this.validateValues()
    this.loadTemplatesView()
    document.addEventListener("openAddInappModal", this.handleOpenModal.bind(this))
  }

  handleOpenModal(e) {
    if (e.detail.type === "replace") {
      this.openModalReplace(e.detail)
    } else {
      this.openModalAdd(e.detail)
    }
  }

  openModalAdd(detail) {
    this.actionInputTarget.value = "add"
    this.submitButtonTarget.innerText = "Add In-App"
    this.element.closest(".modal-card").querySelector(".modal-title").innerText = "Add In-App"

    if(detail.projectType && (detail.projectType === "multi-slider" || detail.projectType === "stories")) {
      let inapps = document.querySelectorAll('#multi_slider .group .inapp')
      let lastInapp = inapps[inapps.length - 1] // Get the last inapp on slider
      if (lastInapp) {
        this.originInputTarget.value = lastInapp.dataset.id
      }
    } else {
      let group = detail.event.currentTarget.closest(".branch")
      let buttonsIds = []
      group.querySelectorAll(".transition-button").forEach((button) => {
        buttonsIds.push(button.dataset.buttonId)
      })
      // Origin = One or several buttons from the section
      this.originInputTarget.value = buttonsIds
    }
  }

  openModalReplace(detail) {
    this.actionInputTarget.value = "replace"
    this.submitButtonTarget.innerText = "Replace In-App"
    this.element.closest(".modal-card").querySelector(".modal-title").innerText = "Replace In-App"
    // Origin = The Inapp to replace
    this.originInputTarget.value = detail.inappId
  }

  validateValues() {
    this.submitButtonTarget.setAttribute("disabled", true)
    if (this.elementIdInputTarget.value != "" && this.elementTypeInputTarget.value != "" && this.originInputTarget.value != "") {
      this.submitButtonTarget.removeAttribute("disabled")
    }
  }

  searchByName(e) {
    let form = e.currentTarget.closest("form")
    form.requestSubmit()
  }

  setSortBy(e) {
    let sortBy = e.currentTarget.dataset.value
    let form = e.currentTarget.closest("form")
    let text = form.querySelector("#sorted_by_text")
    form.querySelector("#sort_by").value = sortBy
    text.innerText = e.currentTarget.dataset.text
    text.parentElement.click()
    form.requestSubmit()
  }

  loadTemplatesView() {
    this.load(this.getTemplatesUrlValue)
  }

  loadLibraryView(e) {
    this.load(this.getInappsUrlValue)
  }

  loadFileView() {
    this.load(this.getFileUrlValue)
  }

  selectElement(e) {
    // Set the values, to the form inputs
    this.elementTypeInputTarget.value = e.currentTarget.dataset.elementType
    this.elementIdInputTarget.value = e.currentTarget.dataset.elementId
    document.querySelectorAll(".add-inapp-card").forEach((card) => {
      card.classList.remove("border-indigo-500")
    })
    e.currentTarget.classList.add("border-indigo-500")
    this.validateValues()
  }

  setEmptyInapp(e) {
    // Set the type of the new element to "Empty"
    this.elementTypeInputTarget.value = "Empty"
    this.elementIdInputTarget.value = ""
    let form = e.currentTarget.closest("form")
    form.requestSubmit()
  }

  load(url) {
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }

  throttle(func, wait = 100) {
    let timer = null
    return function (...args) {
      if (timer === null) {
        timer = setTimeout(() => {
          func.apply(this, args)
          timer = null
        }, wait)
      }
    }
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="editor--forms-shared"
export default class extends Controller {
  updateContent(e) {
    let targetName = e.target.dataset.target
    let target = document.querySelector(targetName)

    if (target) {
      target.innerText = e.target.value
    }
  }
}

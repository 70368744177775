import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="editor--zoom"
export default class extends Controller {
  static values = {
    target: String,
  }

  zoomIn() {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera

    let target = document.querySelector(this.targetValue)
    let zoom = parseFloat(target.style["zoom"]) || 1.0
    if (zoom <= 1.2) {
      zoom = zoom + 0.1
    }
    target.style["zoom"] = zoom
    document.dispatchEvent(new CustomEvent("redrawLines"))
  }

  zoomOut() {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera

    let target = document.querySelector(this.targetValue)
    let zoom = parseFloat(target.style["zoom"]) || 1.0
    if (zoom >= 0.4) {
      zoom = zoom - 0.1
    }
    target.style["zoom"] = zoom
    document.dispatchEvent(new CustomEvent("redrawLines"))
  }
}

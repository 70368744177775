/*
  Hide and Show elements.

  display-hidden-class -> class to be toggled to hide and show element
  display-checked-class ->

*/
import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
  static classes = ["hidden", "checked", "unchecked"]

  /*
  Show or Hide specific element.
  Target element ID should be added as data-element-id
*/
  toggleAnimated(event) {
    event.preventDefault()
    let elementId = event.currentTarget.dataset.elementId
    let element = document.getElementById(elementId)
    if (element.classList.contains(this.hiddenClass)) {
      enter(element)
    } else {
      leave(element)
    }
  }

  toggle(event) {
    event.preventDefault()
    let elementId = event.currentTarget.dataset.elementId
    let element = document.getElementById(elementId)
    element.classList.toggle(this.hiddenClass)
  }

  showElement(event) {
    event.preventDefault()
    let elementId = event.currentTarget.dataset.elementId
    let element = document.getElementById(elementId)
    element.classList.remove(this.hiddenClass)
  }

  /*
  Show or Hide more than one element.
  Target elements classes should be added as data-elements-class
*/
  toggleMultiple(event) {
    event.preventDefault()
    let elementsClass = event.currentTarget.dataset.elementsClass
    let elements = document.querySelectorAll(`.${elementsClass}`)
    elements.forEach((element) => {
      element.classList.toggle(this.hiddenClass)
    })
  }

  /*
  Show or Hide specific element based on selected <option> from <select>.
  data-index -> index of the <option> element that triggles the function
  data-element-id -> ID of the element to be hidden / displayed.
*/

  toggleOnSelect(event) {
    let options = event.target.options
    let index = event.currentTarget.dataset.index
    let selectedValue = options[options.selectedIndex].value
    let elementId = event.currentTarget.dataset.elementId
    let element = document.getElementById(elementId)
    if (index == selectedValue) {
      element.classList.remove(this.hiddenClass)
    } else {
      element.classList.add(this.hiddenClass)
    }
  }

  /*
  Add data-checked-class to element if it's checked.
  Add data-uncheched-class to element if it's not checked.

  Function to be renamed because we can add any class we want to checked elements, not only color classes.
*/

  changeColor(event) {
    let elementId = event.currentTarget.dataset.elementId
    let element = document.getElementById(elementId)
    let multiple = event.currentTarget.dataset.multiple

    if (multiple == "false") {
      this.uncheckAll()
    }
    this.toggleClass(element)
  }

  /*
  Functions to be refactored after dragonfly upgrade fix.
*/
  changeMultipleColors(event) {
    let elements = document.querySelectorAll("[data-color-change]")
    elements.forEach((element) => {
      this.toggleClass(element)
    })
  }

  toggleClass(element) {
    element.classList.toggle(this.uncheckedClass)
    element.classList.toggle(this.checkedClass)
  }

  uncheckAll() {
    let elements = document.querySelectorAll("[data-checked]")
    elements.forEach((element) => {
      element.classList.remove(this.checkedClass)
      element.classList.add(this.uncheckedClass)
    })
  }

  toggleInput(event) {
    let elementInputClass = event.currentTarget.dataset.elementInputClass
    let elements = document.querySelectorAll(`.${elementInputClass}`)
    elements.forEach((element) => {
      element.disabled = !element.disabled
    })
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    inappId: Number,
  }

  triggerOpenModalReplace(e) {
    document.dispatchEvent(
      new CustomEvent("openAddInappModal", {
        detail: {
          event: e,
          type: "replace",
          inappId: this.inappIdValue,
        },
      })
    )
  }
}

import ApplicationController from "../../../javascript/controllers/application_controller"

export default class extends ApplicationController {
  static values = {
    target: String,
    recordType: String,
    recordField: String,
    recordId: String,
  }

  updateFont(e) {
    if (this.targetValue) {
      let targets = document.querySelectorAll(this.targetValue)
      let selectedIndex = e.target.selectedIndex
      let selectedOption = e.target.options[selectedIndex]
      let font = selectedOption.dataset.name
      targets.forEach(target => {
        target.style["font-family"] = font
      })

      this.updateElementField(
        this.recordTypeValue,
        this.recordIdValue,
        this.recordFieldValue,
        selectedOption.value
      )
    }
  }
}

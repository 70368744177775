import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
  static targets = ["content"]
  static values = {
    opened: Boolean,
  }

  connect() {
    if (!this.openedValue) {
      leave(this.contentTarget)
    }

    // Close side form when an element form is displayed
    document.addEventListener("openElementForm", (event) => {
      if (event.detail) {
        this.closeContent()
      }
    })

    // Close if click on workspace
    document.querySelector('#workspace').addEventListener("click", () => {
      this.closeContent()
    })
  }

  openContent() {
    enter(this.contentTarget)
    if(this.element.dataset.event){
      document.dispatchEvent(new CustomEvent(this.element.dataset.event, { detail: { opened: true }}))
    }
  }

  closeContent() {
    leave(this.contentTarget)
    if(this.element.dataset.event){
      document.dispatchEvent(new CustomEvent(this.element.dataset.event, { detail: { opened: false }}))
    }
  }
}

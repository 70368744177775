import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  setInputsValue(e) {
    let newProjectFromTemplateModal = document.querySelector('#create-new-project')
    newProjectFromTemplateModal.querySelectorAll('[name="template_id"]').forEach(input => { 
      input.value = e.currentTarget.dataset.templateId
    })
  }
}

import { Controller } from "@hotwired/stimulus"
import sortable from "html5sortable/dist/html5sortable.es.js"

// Connects to data-controller="editor--multi-inapp--sortable-buttons"
export default class extends Controller {
  static values = {
    projectId: Number,
  }

  connect() {
    sortable(this.element.dataset.sortableName, {
      acceptFrom: this.element.dataset.sortableName,
      placeholderClass: "button-placholder",
      customDragImage: (draggedElement) => {
        return {
          element: draggedElement,
          posX: 0,
          posY: 0,
        }
      },
    })

    sortable(this.element.dataset.sortableName).forEach((sortableEl) => {
      sortableEl.addEventListener("sortupdate", (e) => {
        document.dispatchEvent(new CustomEvent("redrawLines"))
        this.handleChange(e)
        this.adjustSectionsHeight(this.element.dataset.sortableName)
      })
    })

    this.adjustSectionsHeight(this.element.dataset.sortableName)
    document.dispatchEvent(new CustomEvent("redrawLines"))
  }

  adjustSectionsHeight(class_name) {
    let sections = document.querySelectorAll(class_name)
    let maxButtons = 0
    sections.forEach((section) => {
      if (section.children.length > maxButtons) {
        maxButtons = section.children.length
      }
    })
    sections.forEach((section) => {
      section.style["height"] = `calc(${maxButtons}*59.5px)`
    })
  }

  // Dispatch the event to open the modal and set the buttons in the input
  // see add_inapp_modal_component_controller.js #openModalAdd
  triggerOpenModal(e) {
    document.dispatchEvent(
      new CustomEvent("openAddInappModal", {
        detail: {
          event: e,
          type: "add",
        },
      })
    )
  }

  handleChange(e) {
    let button = e.detail.item

    // If the button is moved to a section with an existing branch/target => create the transition
    let destination = e.detail.destination.container
    let origin_branch = e.detail.origin.container.parentElement.querySelector(".tree-branch")
    let destination_branch = destination.parentElement.querySelector(".tree-branch")

    // Delete origin transition if all buttons are moved out of the section
    if (e.detail.origin.items.length === 0) {
      this.handleEmptySection(e.detail.origin)
    }

    if (origin_branch) {
      // Remove existing transition from button if they exisit
      this.callDestroyFromButton(button.dataset.buttonId)
    }

    setTimeout(() => {
      if (destination_branch) {
        // Create new transition from button to this target
        let target_id = destination_branch.dataset.inappId
        this.callCreate(button.dataset.buttonId, target_id)
      }
      document.dispatchEvent(new CustomEvent("updateProjectSize"))
    }, 500)
  }

  handleEmptySection(origin) {
    // Delete origin transition if all buttons are moved
    let branch = origin.container.parentElement.querySelector(".tree-branch")
    if (branch) {
      let target_id = branch.dataset.inappId
      this.callDestroyAll(target_id)
    }
  }

  callCreate(button_id, target_id) {
    fetch(`/projects/${this.projectIdValue}/transitions`, {
      method: "POST",
      headers: this.headers(),
      body: JSON.stringify({
        button_id: button_id,
        target_id: target_id,
      }),
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }

  callDestroyFromButton(button_id) {
    fetch(`/projects/${this.projectIdValue}/transitions/delete`, {
      method: "DELETE",
      headers: this.headers(),
      body: JSON.stringify({
        button_id: button_id,
      }),
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }

  callDestroyAll(target_id) {
    fetch(`/projects/${this.projectIdValue}/transitions/delete_all`, {
      method: "DELETE",
      headers: this.headers(),
      body: JSON.stringify({
        target_id: target_id,
      }),
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }

  headers() {
    return {
      Accept: "text/vnd.turbo-stream.html",
      "Content-Type": "application/json",
      "X-CSRF-Token": document.head.querySelector(`meta[name="csrf-token"]`)?.getAttribute("content"),
    }
  }
}

import { Controller } from "@hotwired/stimulus"
import { initializeCarousels } from "../../external/siema_with_dots"

export default class extends Controller {
  static values = {
    inappId: String,
    url: String,
  }

  loadLayouts(e) {
    let selectedDeviceId = document.querySelector('input[name="selected_device"]').value

    let templateSection = document.querySelector("#templates-section.empty-template-section")
    if (templateSection) {
      fetch(`${this.urlValue}?inapp_id=${this.inappIdValue}&selected_device=${selectedDeviceId}`, {
        method: "GET",
      })
        .then((r) => r.text())
        .then((html) => Turbo.renderStreamMessage(html))
        .then(() => {
          // Ugly workaround to initialize carousels when the templates are loaded
          setTimeout(() => {
            initializeCarousels("#templates-section .carousel")
            window.dispatchEvent(new Event("resize"))
          }, 750)
        })
    }
  }

  saveLayout() {
    let titleInput = this.element.querySelector("#save-as-template input")
    fetch(this.urlValue, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.head.querySelector(`meta[name="csrf-token"]`)?.getAttribute("content"),
      },
      body: JSON.stringify({
        inapp_id: this.inappIdValue,
        title: titleInput.value,
      }),
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
      .then(() => {
        document.dispatchEvent(
          new CustomEvent("openAlert", {
            detail: {
              type: "success",
              text: "Template saved !",
              close: false,
            },
          })
        )
        titleInput.value = ""
      })
  }
}

/*
  Confirmation modals

  The controller should be a form element
  Template should be present in the DOM : See ConfirmationTemplateComponent
    (its id can be provided or there will be a data-confirmation-template attr lookup)
*/
import { Controller } from "@hotwired/stimulus"
import { enter, leave } from 'el-transition'

export default class extends Controller {
  static targets = [ 'panel', 'background' ]
  static values = { templateId: String }

  confirm(e) {
    if (e.detail?.confirmed) return
    e.preventDefault()

    let template = (this.templateIdValue) ? document.getElementById(this.templateIdValue) :
                                            document.querySelector('template[data-confirmation-template]')
    this.element.append(template.content.cloneNode(true))
    enter(this.backgroundTarget)
    enter(this.panelTarget)
  }

  close() {
    leave(this.backgroundTarget)
    leave(this.panelTarget).then(() => {
      this.element.querySelector('[data-confirmation]').remove()
    })
  }

  accept() {
    this.element.dispatchEvent(new CustomEvent('submit', { bubbles: true, cancelable: true, detail: { confirmed: true } }))
    this.close()
  }
}

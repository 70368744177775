import { Controller } from "@hotwired/stimulus"
import sortable from "html5sortable/dist/html5sortable.es.js"

// Connects to data-controller="editor--order-elements"
export default class extends Controller {
  static values = {
    url: String,
    inappId: Number,
  }

  connect() {
    this.initSortable()
    document.addEventListener("initSortable", () => {
      sortable(".items-list")
    })
  }

  initSortable() {
    sortable(".items-list", {
      handle: ".handle",
      placeholderClass: "element-item-placholder",
    })
    sortable(".items-list").forEach((sortableEl) => {
      sortableEl.addEventListener("sortupdate", (e) => {
        this.updateComponentsOrder(e.detail.destination.items)
      })
    })
  }

  updateComponentsOrder(items) {
    let selectedDeviceId = document.querySelector('input[name="selected_device"]').value

    var elements = []
    items.forEach((item, i) => {
      elements[i] = item.querySelector(".element-item").dataset.id
    })
    fetch(this.urlValue, {
      method: "PUT",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        elements: elements,
        inappId: this.inappIdValue,
        selected_device: selectedDeviceId
      }),
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="editor--layout-event-listeners"

// this.targetElement can't be set in connect because the inapp is rerendered
// and this.targetElement would refer to the old node
export default class extends Controller {
  static values = {
    targetId: String,
  }

  connect() {
    document.addEventListener("isFullscreenChanged", this.handleIsFullscreenChanged.bind(this))
    document.addEventListener("modalPositionChanged", this.handleModalPositionChanged.bind(this))
    document.addEventListener("verticalAlignChanged", this.handleVerticalAlignChanged.bind(this))
    document.addEventListener("isImageBackgroundChanged", this.handleisImageBackgroundChanged.bind(this))
    document.addEventListener("hasCloseButtonChanged", this.handleHasCloseButtonChanged.bind(this))
    document.addEventListener("hasCloseButtonBackgroundChanged", this.handleHasCloseButtonBackgroundChanged.bind(this))
    document.addEventListener("darkModeChanged", this.handleDarkModeChanged.bind(this))
  }

  handleIsFullscreenChanged(e) {
    this.targetElement = document.querySelector(this.targetIdValue)
    // Manage inputs
    let modal_inputs = document.getElementById("modal_inputs")
    let fullscreen_inputs = document.getElementById("fullscreen_inputs")
    if (e.detail.value === "false") {
      this.hide(fullscreen_inputs)
      this.show(modal_inputs)
    } else {
      this.show(fullscreen_inputs)
      this.hide(modal_inputs)
    }

    // Apply changes
    if (e.detail.value === "false") {
      this.targetElement.style["height"] = "auto"
      let modalWidth = modal_inputs.querySelector('#layout_modal_width')
      this.targetElement.style["width"] = modalWidth.value

    } else {
      this.targetElement.style["height"] = "100%"
      this.targetElement.style["width"] = "100%"
    }
  }

  handleModalPositionChanged(e) {
    this.targetElement = document.querySelector(this.targetIdValue)
    // Apply changes
    this.targetElement.parentElement.style["justify-content"] = e.detail.value
  }

  handleVerticalAlignChanged(e) {
    this.targetElement = document.querySelector(this.targetIdValue)
    // Apply changes
    this.targetElement.style["justify-content"] = e.detail.value
  }

  handleisImageBackgroundChanged(e) {
    this.targetElement = document.querySelector(this.targetIdValue)
    // Manage inputs
    let color_input = document.getElementById("background_color_input")
    let image_input = document.getElementById("background_image_input")
    if (e.detail.value === "false") {
      this.show(color_input)
      this.hide(image_input)
    } else {
      this.hide(color_input)
      this.show(image_input)
    }
  }

  handleHasCloseButtonChanged(e) {
    this.targetElement = document.querySelector(this.targetIdValue)
    // Manage inputs
    let inputs = document.getElementById("close_button_inputs")
    e.detail.value === "false" ? this.hide(inputs) : this.show(inputs)
    // Apply changes
    let closeButton = this.targetElement.querySelector(".close-button")
    if (e.detail.value === "false") {
      closeButton.style["display"] = "none"
    } else {
      closeButton.style["display"] = "flex"
    }
  }

  handleHasCloseButtonBackgroundChanged(e) {
    this.targetElement = document.querySelector(this.targetIdValue)
    // Manage inputs
    let inputs = document.getElementById("close_button_background_inputs")
    e.detail.value === "false" ? this.hide(inputs) : this.show(inputs)

    // Apply changes
    let closeButton = this.targetElement.querySelector(".close-button")
    if (e.detail.value === "false") {
      closeButton.style["background"] = "transparent"
      closeButton.style["border-color"] = "transparent"
    } else {
      let selectedColor = document.getElementById("layout_close_circle_background_color_id")
      let selectedColorValue = selectedColor.parentElement.querySelector(`[data-color-id='${selectedColor.value}']`).dataset.colorValue
      closeButton.style["background"] = selectedColorValue

      let selectedBorderColor = document.getElementById("layout_close_circle_border_color_id")
      let selectedBorderColorValue = selectedColor.parentElement.querySelector(`[data-color-id='${selectedBorderColor.value}']`).dataset.colorValue
      closeButton.style["border-color"] = selectedBorderColorValue
    }
  }

  handleDarkModeChanged(e) {
    // Manage inputs
    let sections = document.querySelectorAll(".dark-mode-inputs")
    let darkModeStyles = document.querySelectorAll('style.dark_mode_css')
    if(e.detail.value === "false"){
      sections.forEach(section => {
        this.hide(section) 
      })
      darkModeStyles.forEach(darkModeStyle => {
        darkModeStyle.media = "max-width: 1px"   // Workaround : media to ignore the style tag if dark mode is disabled
      })  
    } else {
      sections.forEach(section => {
        this.show(section)
      })
      darkModeStyles.forEach(darkModeStyle => {
        darkModeStyle.removeAttribute('media')
      })  
    }
  }

  hide(node) {
    node.classList.add("hidden")
  }

  show(node) {
    node.classList.remove("hidden")
  }
}

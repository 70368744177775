import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String,
  }

  openToggleForm(e) {
    e.stopPropagation()
    document.dispatchEvent(new CustomEvent("openElementForm", { detail: { id: e.target.dataset.id, skipClose: true } }))
  }

  addToggle(e) {
    let selectedDeviceId = document.querySelector('input[name="selected_device"]').value

    fetch(this.urlValue, {
      method: "POST",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.head.querySelector(`meta[name="csrf-token"]`)?.getAttribute("content"),
      },
      body: JSON.stringify({
        selected_device: selectedDeviceId
      })
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }
}

import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="editor--element-selection"
export default class extends Controller {
  static targets = ["element", "sideItem"]

  connect() {
    document.addEventListener("initElementSelection", this.initializeElements.bind(this))
  }

  initializeElements() {
    if(!document.querySelector('#workspace')){
      return
    }
    this.hoverElement = this.throttle(this.handleHoverElement.bind(this), 50)
    this.hoverSideItem = this.throttle(this.handleHoverSideItem.bind(this), 50)
    this.mouseLeave = this.throttle(this.unselectAllElements.bind(this), 50)

    this.clickElement = this.handleClickElement.bind(this)
    this.clickSideItem = this.handleClickSideItem.bind(this)

    let elements = document.querySelectorAll("#workspace .inapp-element")
    // Set data attributes on each element (added by JS to avoid having it on export)
    Array.from(elements).forEach((element) => {
      element.setAttribute("data-editor--element-selection-target", "element")
      // Add event listeners on "hover" and "click" for each element
      element.addEventListener("mouseover", this.hoverElement)
      element.addEventListener("mouseleave", this.mouseLeave)
      // Don't add the click listener on the carousel to avoid weird open/close on swipe
      if(!element.classList.contains('carousel_wrapper')){
        element.addEventListener("click", this.clickElement)
      }
    })

    this.sideItemTargets.forEach((item) => {
      item.addEventListener("mouseover", this.hoverSideItem)
      item.addEventListener("mouseleave", this.mouseLeave)
      item.addEventListener("click", this.clickSideItem)
    })

    // When click outside the inapp -> remove all section + add re-add event listeners
    document.querySelector('#workspace').addEventListener("click", () => {
      this.unselectAllElements()
      this.addListenersAllElements()

      // Open with no ID passed in event = close all
      document.dispatchEvent(new CustomEvent("openElementForm"))
    })

    document.addEventListener("elementFormClosed", () => {
      this.addListenersAllElements()
    })
  }

  handleHoverElement(e) {
    e.stopPropagation()
    let element = e.target.closest(".inapp-element")
    let sideItem = document.querySelector(`.element-item[data-id='${element.dataset.id}']`)

    this.unselectAllElements()
    if (element) {
      element.classList.add("selected-element")
    }
    if (sideItem) {
      sideItem.classList.add("selected-element-item")
    }
  }

  handleHoverSideItem(e) {
    e.stopPropagation()
    let sideItem = e.target.closest(".element-item")
    let element = document.querySelector(`.inapp-element[data-id='${sideItem.dataset.id}']`)

    this.unselectAllElements()
    if (element) {
      element.classList.add("selected-element")
    }
    if (sideItem) {
      sideItem.classList.add("selected-element-item")
    }
  }

  handleClickElement(e) {
    e.stopPropagation()
    let element = e.currentTarget.closest(".inapp-element")
    let elementId = element.dataset.id
    let sideItem = document.querySelector(`.element-item[data-id='${elementId}']`)

    this.selectElement(element, sideItem, elementId)
  }

  handleClickSideItem(e) {
    e.stopPropagation()

    let sideItem = e.target.closest(".element-item")
    let elementId = sideItem.dataset.id
    let element = document.querySelector(`.inapp-element[data-id='${elementId}']`)
    this.selectElement(element, sideItem, elementId)
  }

  selectElement(element, sideItem, elementId) {
    // Remove all listeners
    this.removeListenersAllElements()
    this.unselectAllElements()
    if (element) {
      element.classList.add("selected-element")
      element.removeEventListener("mouseleave", this.mouseLeave)
    }
    if (sideItem) {
      sideItem.removeEventListener("mouseleave", this.mouseLeave)
    }
    // Show side form with selected element form
    document.dispatchEvent(new CustomEvent("openElementForm", { detail: { id: elementId } }))
  }

  unselectAllElements() {
    this.removeHoverStyles()
  }

  addListenersAllElements() {
    this.elementTargets.forEach((element) => {
      element.addEventListener("mouseover", this.hoverElement)
      element.addEventListener("mouseleave", this.mouseLeave)
    })
    this.sideItemTargets.forEach((item) => {
      item.addEventListener("mouseover", this.hoverSideItem)
      item.addEventListener("mouseleave", this.mouseLeave)
    })
  }

  removeListenersAllElements() {
    this.elementTargets.forEach((element) => {
      element.removeEventListener("mouseover", this.hoverElement)
      element.removeEventListener("mouseleave", this.mouseLeave)
    })
    this.sideItemTargets.forEach((item) => {
      item.removeEventListener("mouseover", this.hoverSideItem)
      item.removeEventListener("mouseleave", this.mouseLeave)
    })
  }

  removeHoverStyles() {
    this.elementTargets.forEach((element) => {
      element.closest(".inapp-element").classList.remove("selected-element")
    })
    this.sideItemTargets.forEach((item) => {
      item.classList.remove("selected-element-item")
    })
  }

  throttle(func, wait = 100) {
    let timer = null
    return function (...args) {
      if (timer === null) {
        timer = setTimeout(() => {
          func.apply(this, args)
          timer = null
        }, wait)
      }
    }
  }
}

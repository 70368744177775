import ApplicationController from "../../../javascript/controllers/application_controller"

export default class extends ApplicationController {
  static targets = ["element", "input"]
  static classes = ["selected"]
  static values = {
    default: String,
    eventName: String,
  }

  connect() {
    super.connect()
    this.unselectAll()
    this.elementTargets.forEach((element) => {
      if (element.dataset.value === this.defaultValue) {
        setTimeout(() => {
          this.selectElement(element, false)
        }, 100)
      }
    })
  }

  unselectAll() {
    this.elementTargets.forEach((element) => {
      element.classList.remove(...this.selectedClasses)
    })
  }

  select(event) {
    this.selectElement(event.currentTarget)
  }

  selectElement(element, dispatch = true) {
    this.unselectAll()
    element.classList.add(...this.selectedClasses)
    let value = element.dataset.value
    this.changeInputValue(value)
    // Don't dispatch event when the controller connects
    if (dispatch) {
      document.dispatchEvent(new CustomEvent(this.eventNameValue, { detail: { element: element, value: value } }))

      let recordType = this.inputTarget.dataset.recordType
      let recordId = this.inputTarget.dataset.recordId
      let recordField = this.inputTarget.dataset.recordField

      this.updateElementField(recordType, recordId, recordField, value)
    }
  }

  changeInputValue(value) {
    this.inputTarget.value = value
    this.cssTargetValue = this.inputTarget.dataset.cssTarget
    this.cssRuleValue = this.inputTarget.dataset.cssRule
    this.customCssValue = this.inputTarget.dataset.customCssChange

    // Apply changes
    if (this.cssTargetValue && this.cssRuleValue) {
      let targets = document.querySelectorAll(this.cssTargetValue)
      if (targets) {
        targets.forEach(target => {
          this.cssRuleValue.split(" ").forEach((rule) => {
            target.style[rule] = value
          })
        })
      }
    }

    if (this.customCssValue) {
      this.applyCustomChange(this.customCssValue, value)
    }
  }

  applyCustomChange(custom_change, value) {
    var target = document.querySelector(this.cssTargetValue)
    switch (custom_change) {
      // Workaround to reset margins when
      case "remove-buttons-margins":
        let buttons = target.querySelectorAll(".button")
        buttons.forEach((button) => {
          // Get all Margins in SideForm
          let sideForm = document.querySelector(`[data-components--forms--element-form-element-id-value='${button.dataset.id}']`)
          let marginInputs = sideForm.querySelectorAll("[name^=value_margin_]")
          marginInputs.forEach((input) => {
            input.value = 0
            input.dispatchEvent(new Event("keyup"))
          })
        })
        break
      case "video-controls-changed":
        let controls = target.querySelector('.video-controls')
        let form = this.element.closest('form')
        let marginValue = form.querySelector('[name="video_element[controls_margin]"]').value
        if (value === 'bottom') {
          controls.style['bottom'] = marginValue
          controls.style.removeProperty('top')
        } else {
          controls.style['top'] = marginValue
          controls.style.removeProperty('bottom')
        }
        break
      case "indicators-position-changed":
        let indicatorsTargets = document.querySelectorAll(this.cssTargetValue)
        if (indicatorsTargets) {
          if (value === 'bottom') {
            indicatorsTargets.forEach(indicatorsTarget => {
              indicatorsTarget.style['bottom'] = 0
              indicatorsTarget.style.removeProperty('top')
            })
          } else {
            indicatorsTargets.forEach(indicatorsTarget => {
              indicatorsTarget.style['top'] = 0
              indicatorsTarget.style.removeProperty('bottom')
            })
          }
        }
        break
      default:
        break
    }
  }
}

import ApplicationController from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {

  handleChange(e) {
    let recordType = e.currentTarget.dataset.recordType
    let recordId = e.currentTarget.dataset.recordId
    let field = e.currentTarget.dataset.field
    if (recordType && recordId && field) {
      this.updateElementField(recordType, recordId, field, e.currentTarget.checked)
    }
  }
}

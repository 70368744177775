import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    let selectedDeviceInputs = document.querySelectorAll('input[name="selected_device"]')
    selectedDeviceInputs.forEach(input => {
      input.value = 4 // iPhone 
    })
  }

  changeDevice() {
    let selectedIndex = this.element.selectedIndex
    let selectedOption = this.element.options[selectedIndex]

    let selectedDeviceInputs = document.querySelectorAll('input[name="selected_device"]')
    selectedDeviceInputs.forEach(input => {
      input.value = selectedIndex
    })

    document.dispatchEvent(new Event("rerenderCarousels"))

    let targets = document.querySelectorAll(".device")
    let height = selectedOption.dataset.height
    let width = selectedOption.dataset.width
    let radius = selectedOption.dataset.radius
    targets.forEach((device) => {
      device.style["height"] = height + "px"
      device.style["width"] = width + "px"
      device.style["border-radius"] = radius + "px"
      device.parentElement.querySelector(".inapp-wrapper").parentElement.style["border-radius"] = radius + "px"
    })
  }

}

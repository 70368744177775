import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="paywall"
export default class extends Controller {
  static targets = ['monthlyCard', 'yearlyCard', 'badge', 'oneTimeSection', 'subscriptionSection']
  static values = {
    view: String
  }

  connect() {
    // Remove the paywall params in url when displayed
    window.history.replaceState(null, '', window.location.pathname);
    switch (this.viewValue) {
      case "yearly":
        this.showYearly()
        break;
      case "monthly":
        this.showMonthly()
        break;
      case "one-time":
        this.showOneTime()
        break;
      default:
        this.showMonthly()
        break;
    }

  }

  changeFrequency(e) {
    let frequencySelected = e.currentTarget.dataset.frequency
    if (frequencySelected == 'yearly') {
      this.showYearly()
    } else if (frequencySelected == 'monthly') {
      this.showMonthly()
    } else {
      this.showOneTime()
    }
  }

  unselectBadges() {
    this.badgeTargets.forEach(target => {
      target.classList.remove('bg-indigo-600', 'text-white')
    });
  }

  showSection(target) {
    target.classList.remove('hidden')
  }

  hideSection(target) {
    target.classList.add('hidden')
  }

  showYearly() {
    this.unselectBadges()
    document.querySelector('label[data-frequency="yearly"]').classList.add('bg-indigo-600', 'text-white')

    this.showSection(this.subscriptionSectionTarget)
    this.hideSection(this.oneTimeSectionTarget)

    this.monthlyCardTargets.forEach(target => {
      target.classList.add('hidden')
    });
    this.yearlyCardTargets.forEach(target => {
      target.classList.remove('hidden')
    });
  }

  showMonthly() {
    this.unselectBadges()
    document.querySelector('label[data-frequency="monthly"]').classList.add('bg-indigo-600', 'text-white')

    this.showSection(this.subscriptionSectionTarget)
    this.hideSection(this.oneTimeSectionTarget)

    this.yearlyCardTargets.forEach(target => {
      target.classList.add('hidden')
    });
    this.monthlyCardTargets.forEach(target => {
      target.classList.remove('hidden')
    });
  }

  showOneTime() {
    this.unselectBadges()
    document.querySelector('label[data-frequency="once"]').classList.add('bg-indigo-600', 'text-white')

    this.showSection(this.oneTimeSectionTarget)
    this.hideSection(this.subscriptionSectionTarget)
  }
}

import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="preview-liquid"
export default class extends Controller {

  // If the content contains Liquid language => Update with 1st element of liquid hash
  connect() {
    let isInput = this.element.classList.contains('input-text')
    let isToggle = this.element.classList.contains('toggle')
    let content, type = ""
    if(isInput) {
      type = "input"
      content = this.element.placeholder
    } else if (isToggle){
      type = "toggle"
      content = this.element.querySelector('span').innerText
    } else {
      type = "default"
      content = this.element.innerText
    }
    if(content.includes('{%')) {
      this.updateContent(type, content)
    }
  }

  updateContent(type, content){
    var firstElementLiquidHash = this.parseString(content)
    var value = Object.values(firstElementLiquidHash)[0]
    switch (type) {
      case "input":
        this.element.placeholder = value
        break;
      case "toggle":
        this.element.querySelector('span').innerText = value
        break;
      default:
        this.element.innerText = value
        break;
    }

  }

  parseString(string) {
    let conditions = string.match(/{%(.*?)%}/g)
    if (!conditions) {
      return []
    }
    let parsedHash = {}
    for (let i = 0; i < conditions.length - 1; i++) {
      var value = string.substring(
                    string.indexOf(conditions[i]) + conditions[i].length,
                    string.indexOf(conditions[i + 1])
                  )
      if(conditions[i].replace(/\s/g, "") === "{%else%}"){
        var key = 'default'
      } else {
        var key = conditions[i]
      }
      parsedHash[key] = value.replace(/\n/g, '')
    }
    return parsedHash // parsedHash = [["test", value]]
  }
}

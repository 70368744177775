import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
  static values = {
    elementId: String,
  }

  connect() {
    let selectedDevice = document.getElementById('selected_device')
    if (selectedDevice) {
      let selectedDeviceInputs = document.querySelectorAll('input[name="selected_device"]')
      selectedDeviceInputs.forEach(input => {
        input.value = selectedDevice.value
      })
    }

    document.addEventListener("openElementForm", (event) => {
      if (event.detail && event.detail.id === this.elementIdValue) {
        this.openContent()
      } else {
        if (!event.detail || !event.detail.skipClose) {
          this.closeContent()
        }
      }
    })
  }

  preventClose(e) {
    //e.preventDefault()
    e.stopPropagation()
  }

  openContent() {
    enter(this.element)
    let inputToFocus
    switch (this.element.dataset.type) {
      case "TextElement":
        inputToFocus = this.element.querySelector("#text_element_content")
        break;
      case "MultipleSelectOption":
        inputToFocus = this.element.querySelector("#multiple_select_option_content")
        break;
      case "ButtonElement":
        inputToFocus = this.element.querySelector("#button_element_content")
        break;
      default:
        break;
    }
    if (inputToFocus) {
      inputToFocus.select()
    }
  }

  closeClicked() {
    this.closeContent()
    // Remove the selected classes on the element
    let selectedElement = document.querySelector(`.inapp-element[data-id='${this.elementIdValue}']`)
    if (selectedElement) {
      selectedElement.classList.remove("selected-element")
    }
    document.dispatchEvent(new Event("elementFormClosed"))
  }

  closeContent() {
    leave(this.element)
  }
}

import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="intercom"
export default class extends Controller {
  static values = {
    content: String,
    event: String
  }

  openWithMessage() {
    Intercom('showNewMessage', this.contentValue);
    if (this.eventValue) {
      Intercom('trackEvent', this.eventValue, { date: new Date() });
    }
  }
}

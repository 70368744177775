import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="editor--liquid"
export default class extends Controller {
  static targets= ["checkbox", "dropdown", "content"]

  connect() {
    this.form = this.element.closest('form')
    this.parseLiquid()
    this.handleCheckboxValue()
  }

  toggle() {
    this.parseLiquid()
    this.handleCheckboxValue()
  }

  dropdownChanged() {
    this.handleCheckboxValue()
  }

  handleCheckboxValue() {
    var nonLiquidField = this.form.querySelector(this.contentTarget.dataset.nonLiquidField)

    if(this.checkboxTarget.checked && this.contentTarget.value !== ""){
      var selectedIndex = this.dropdownTarget.selectedIndex
      var selectedOption = this.dropdownTarget.options[selectedIndex]
      var newContent = this.liquidObject[selectedOption.value]
      nonLiquidField.disabled = true
      this.applyContent(newContent)
    } else {
      // BLOCK Normal Input if checked
      nonLiquidField.removeAttribute('disabled')
      var content = nonLiquidField.value
      this.applyContent(content)
    }
  }

  contentChanged(e){
    this.parseLiquid()
    this.handleCheckboxValue()
  }

  applyContent(value) {
    var target = document.querySelector(this.contentTarget.dataset.target)
    if (target) {
      if(target.classList.contains('input-text')) {
        target.placeholder = value
      } else {
        target.innerText = value
      }
    }
  }

  parseLiquid(){
    this.liquidObject = this.parseString(this.contentTarget.value)
    this.updateDropdownOptions()
  }

  updateDropdownOptions(){
    this.dropdownTarget.innerHTML = ""
    var keys = Object.keys(this.liquidObject);
    keys.forEach(key => {
      var option = document.createElement('option')
      option.value = key
      option.innerText = key
      this.dropdownTarget.appendChild(option)
    })
  }

  // input : Liquid String
  // output: { "== FR": "Bonjour", "== EN": "Hello"}
  parseString(string) {
    let conditions = string.match(/{%(.*?)%}/g)
    if (!conditions) {
      return []
    }
    let parsedHash = {}
    for (let i = 0; i < conditions.length - 1; i++) {
      var value = string.substring(
                    string.indexOf(conditions[i]) + conditions[i].length,
                    string.indexOf(conditions[i + 1])
                  )
      if(conditions[i].replace(/\s/g, "") === "{%else%}"){
        var key = 'default'
      } else {
        var key = this.extractValueFromLiquid(conditions[i])
      }
      parsedHash[key] = value.replace(/\n/g, '')
    }
    return parsedHash // parsedHash = [["test", value]]
  }


  // input : '{% if {{custom_attribute.${language}}} == EN %}'
  // output : '== EN'
  extractValueFromLiquid(string) {
    var str = string.replace("%}", "")
    var lastBracketIndex = str.lastIndexOf("}")
    return str.substring(lastBracketIndex + 1, str.length).trim()
  }
}

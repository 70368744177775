import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="account--firm"
export default class extends Controller {
  static targets = ["logoText"]

  handleLogoChanged(e) {
    let file = e.currentTarget.files[0]
    if (file.name) {
      this.logoTextTarget.innerText = file.name
    }
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  successAlert(text, close) {
    document.dispatchEvent(
      new CustomEvent("openAlert", {
        detail: {
          type: "success",
          text: text,
          close: close,
        },
      })
    )
  }

  updateElementField(recordType, recordId, recordField, value) {
    fetch("/elements/update_field", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.head.querySelector(`meta[name="csrf-token"]`)?.getAttribute("content"),
      },
      body: JSON.stringify({
        record_type: recordType,
        record_id: recordId,
        record_field: recordField,
        value: value
      })
    })
  }
}

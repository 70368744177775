import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="editor--multi-slider--inapps"
export default class extends Controller {
  triggerOpenModal(e) {
    document.dispatchEvent(
      new CustomEvent("openAddInappModal", {
        detail: {
          event: e,
          type: "add",
          projectType: e.currentTarget.dataset.projectType
        },
      })
    )
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String,
  }

  connect() {
    document.addEventListener("updateProjectSize", this.getSize.bind(this))
  }

  getSize() {
    fetch(this.urlValue, {
      method: "GET",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }
}

import ApplicationController from "../../../javascript/controllers/application_controller"

export default class extends ApplicationController {
  static targets = ["input"]
  static values = {
    customCssChange: String,
    cssTarget: String,
    cssRule: String,
    recordType: String,
    recordField: String,
    recordId: String,
  }

  preventDefault(e) {
    e.preventDefault()
  }

  increment() {
    this.inputTarget.stepUp()
    this.inputTarget.dispatchEvent(new Event("change"))
    this.updateField()
  }

  decrement() {
    this.inputTarget.stepDown()
    this.inputTarget.dispatchEvent(new Event("change"))
    this.updateField()
  }

  updateField() {
    this.updateElementField(
      this.recordTypeValue,
      this.recordIdValue,
      this.recordFieldValue,
      this.inputTarget.value
    )
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="editor--multiple-select-options"
export default class extends Controller {
  static targets = ["input", "maxInput"]
  static values = {
    url: String,
  }

  connect() {
    this.maxInputTarget.max = this.inputTarget.value
  }

  stepUp() {
    if (this.inputTarget.value == 10) {
      return
    }
    this.inputTarget.stepUp()
    this.sendRequest("POST")
    this.updateMaxSelection()
  }

  stepDown() {
    if (this.inputTarget.value == 1) {
      return
    }
    this.inputTarget.stepDown()
    this.sendRequest("DELETE")
    this.updateMaxSelection()
  }

  updateMaxSelection() {
    let value = this.inputTarget.value
    this.maxInputTarget.max = value
    if (this.maxInputTarget.value >= this.maxInputTarget.max) {
      this.maxInputTarget.value = this.maxInputTarget.max
      this.maxInputTarget.dispatchEvent(new Event("change"))
    }
  }

  sendRequest(method) {
    let selectedDeviceId = document.querySelector('input[name="selected_device"]').value

    fetch(this.urlValue, {
      method: method,
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.head.querySelector(`meta[name="csrf-token"]`)?.getAttribute("content"),
      },
      body: JSON.stringify({
        selected_device: selectedDeviceId
      })
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }
}

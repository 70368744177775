import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="editor--image-form"
export default class extends Controller {
  connect() {
    this.imageTarget = this.element.dataset.imageTarget
    let imageId = this.imageTarget.slice(1)
    document.addEventListener(`keepRatioChanged_${imageId}`, this.handleKeepRatioChanged.bind(this))
  }

  handleKeepRatioChanged(e) {
    let inputs = document.querySelector('#image-height-input')
    let target = document.querySelector(this.imageTarget)
    let heightInput = this.element.querySelector('#image_element_height')
    if (e.detail) {
      if (e.detail.value === "false") {
        inputs.classList.remove('hidden')
        target.style['height'] = heightInput.value
      } else {
        inputs.classList.add('hidden')
        target.style['height'] = ''
      }
    }
  }

}

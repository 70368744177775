import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggleButton", "iconEye", "iconEyeClosed"]
  
  static values = {
    id: Number
  }

  toggleForeground(e) {
    e.preventDefault()

    var foreground = e.currentTarget.dataset.foreground === "true" ? "false" : "true"
    this.toggleButtonTarget.dataset.foreground = foreground

    this.iconEyeClosedTarget.classList.add('hidden')
    this.iconEyeTarget.classList.add('hidden')
    
    if(foreground === "true") {
      this.iconEyeTarget.classList.remove('hidden')
      
    } else {
      this.iconEyeClosedTarget.classList.remove('hidden')
    }

    document.dispatchEvent(new CustomEvent(`refreshCanvas${this.idValue}`, { detail: { foreground: foreground }}))
  }
}

export default class Video {
  constructor(id, autoplay = false) {
    this.nbPlays = 0
    this.isFullcreen = false
    this.video = document.getElementById(id)
    if(!this.video) { return }
    this.inapp = this.video.closest(".inapp")
    this.controls = this.video.parentNode.querySelector(".video-controls")
    // var videoControlsText_#{inapp.id} = modal_#{inapp.id}.querySelector('.video-controls__text');
    // var cross_#{inapp.id} = modal_#{inapp.id}.querySelector('.cross_#{inapp.id}');
    this.bigPlayIcon = this.video.parentNode.querySelector(".video-controls--big-play")
    this.soundOnIcon = this.controls.querySelector(".video-controls--sound-on")
    this.soundOffIcon = this.controls.querySelector(".video-controls--sound-off")
    this.backTenSecondsIcon = this.controls.querySelector(".video-controls--back-ten-seconds")
    this.playIcon = this.controls.querySelector(".video-controls--play")
    this.pauseIcon = this.controls.querySelector(".video-controls--pause")
    this.fullscreenIcon = this.controls.querySelector(".video-controls--fullscreen")
    this.fullscreenOffIcon = this.controls.querySelector(".video-controls--fullscreen-off")

    this.addEventActions()
    this.hide(this.soundOnIcon)
    this.hide(this.fullscreenOffIcon)
    autoplay ? this.play() : this.pause()
  }


  addEventActions() {
    this.video.addEventListener("click", () => {
      this.toggleControls()
    })
    this.video.addEventListener("ended", () => {
      this.show(this.controls)
      this.show(this.bigPlayIcon)
      this.show(this.playIcon)
      this.hide(this.pauseIcon)
    })
    this.bigPlayIcon.addEventListener("click", () => {
      this.play()
    })
    this.playIcon.addEventListener("click", () => {
      this.play()
    })
    this.soundOnIcon.addEventListener("click", () => {
      this.toggleMute()
    })
    this.soundOffIcon.addEventListener("click", () => {
      this.toggleMute()
    })
    this.backTenSecondsIcon.addEventListener("click", () => {
      this.backTenSeconds()
    })
    this.pauseIcon.addEventListener("click", () => {
      this.pause()
    })
    this.fullscreenIcon.addEventListener("click", () => {
      this.toggleFullscreen()
    })
    this.fullscreenOffIcon.addEventListener("click", () => {
      this.toggleFullscreen()
    })
  }

  play() {
    this.video.play()
    this.hide(this.playIcon)
    this.hide(this.bigPlayIcon)
    this.show(this.pauseIcon)
    this.nbPlays += 1

    setTimeout(() => {
      this.hide(this.controls)
    }, 3000)
  }

  pause() {
    this.video.pause()
    this.show(this.playIcon)
    this.show(this.bigPlayIcon)
    this.hide(this.pauseIcon)
  }

  backTenSeconds() {
    this.video.currentTime = this.video.currentTime - 10
  }

  toggleFullscreen() {
    this.isFullcreen = !this.isFullcreen
    // If Fullscreen :
    // - remove relative to Inapp -> relative to the screen
    // - Hide Close button (moved on top because of the remove relative position on inapp level)
    // - Add Fullscreen class the the video wrapper
    if (this.isFullcreen) {
      this.inapp.style.position = "unset"
      this.inapp.querySelector(".close-button").style.display = "none"
      this.video.closest(".video-fullscreen-wrapper").classList.add("video-fullscreen")
      this.hide(this.fullscreenIcon)
      this.show(this.fullscreenOffIcon)
    } else {
      this.inapp.style.position = "relative"
      this.inapp.querySelector(".close-button").style.display = "block"
      this.video.closest(".video-fullscreen-wrapper").classList.remove("video-fullscreen")
      this.hide(this.fullscreenOffIcon)
      this.show(this.fullscreenIcon)
    }
  }

  toggleMute() {
    this.video.muted = !this.video.muted
    if (this.video.muted) {
      this.show(this.soundOffIcon)
      this.hide(this.soundOnIcon)
    } else {
      this.hide(this.soundOffIcon)
      this.show(this.soundOnIcon)
    }
  }

  toggleControls() {
    if (this.controls.style.display == "none") {
      this.show(this.controls)
    } else {
      this.hide(this.controls)
    }
  }

  getMobileOperatingSystem() {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera
    if (/android/i.test(userAgent)) {
      return "Android"
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS"
    }
    return "unknown"
  }

  hide(element) {
    element.style["display"] = "none"
  }

  show(element) {
    element.style["display"] = "flex"
  }
}

// Entry point for the build script in your package.json
if (!window.Turbo) {
  require("@hotwired/turbo-rails")
}
import * as ActiveStorage from "@rails/activestorage"
import "chartkick/chart.js"
import "../components"
import Video from "../javascript/external/video.js"
import "./controllers"
ActiveStorage.start
window.Video = Video

// Change default Confirmation modal
Turbo.setConfirmMethod((message, element) => {
  let dialog = document.getElementById("turbo-confirm")
  dialog.querySelector(".title").textContent = message
  if (element.dataset.confirmMessage) {
    dialog.querySelector(".message").textContent = element.dataset.confirmMessage
  }
  dialog.classList.remove("hidden")

  return new Promise((resolve, reject) => {
    dialog.querySelectorAll("button").forEach((button) => {
      button.addEventListener(
        "click",
        (e) => {
          dialog.classList.add("hidden")
          resolve(e.currentTarget.value == "confirm")
        },
        { once: true }
      )
    })
  })
})

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="editor--multi-slider--events"
export default class extends Controller {
  connect() {
    document.addEventListener("hasMultiSliderIndicatorsDisplayChanged", this.handleIndicatorDisplayChanged.bind(this))
    document.addEventListener("hasMultiSliderCloseButtonChanged", this.handleCloseDisplayChanged.bind(this))
  }

  handleIndicatorDisplayChanged(e){
    let inputsSection = document.querySelector(".multi-slider-indicators-inputs")
    let targetId = inputsSection.closest('form').dataset.indicatorsId
    let indicators = document.querySelectorAll(`.indicators-slider-${targetId}`)
    if(e.detail.value === "false"){
      this.hide(inputsSection)
      indicators.forEach(indicator => {
        indicator.style['display'] = 'none'
      })
    } else {
      this.show(inputsSection)
      indicators.forEach(indicator => {
        indicator.style['display'] = 'block'
      })
    }
  }


  handleCloseDisplayChanged(e){
    // Manage inputs
    let inputsSection = document.querySelector(".multi-slider-close-inputs")
    let targetId = inputsSection.closest('form').dataset.closeId
    let closeButtons = document.querySelectorAll(`.close-button-slider-${targetId}`)

    if(e.detail.value === "false"){
      this.hide(inputsSection) 
      closeButtons.forEach(closeButton => {
        closeButton.style['display'] = 'none'
      })
    } else {
      this.show(inputsSection)
      closeButtons.forEach(closeButton => {
        closeButton.style['display'] = 'flex'
      })
    }
  }



  hide(node) {
    node.classList.add("hidden")
  }

  show(node) {
    node.classList.remove("hidden")
  }
}

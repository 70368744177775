import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {

  open(event) {
    event.preventDefault()
    let elementId = event.currentTarget.dataset.id
    if (!elementId) {
      console.error("No ID for : ", event.currentTarget)
    }
    let element = document.getElementById(elementId)
    enter(element)
  }

  close(event) {
    let elementId = event.currentTarget.dataset.id
    if (!elementId) {
      console.error("No ID for : ", event.currentTarget)
    }
    let element = document.getElementById(elementId)
    leave(element)
  }
}

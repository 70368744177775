import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "input"]

  connect() {
    this.editModal = document.getElementById("edit-tag-modal")
  }

  searchTags(e) {
    if (e.keyCode === 13) {
      this.clearInput()
      return
    }
    this.search(e.currentTarget.value)
  }

  search(value) {
    fetch(`/tags/search?name=${value}`, {
      method: "GET",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }

  addTag(e) {
    this.inputTarget.value = e.currentTarget.dataset.tagName
    this.formTarget.requestSubmit()
    this.clearInput()
  }

  editTag(e) {
    e.stopPropagation()
    this.openModal(e)
  }

  clearInput() {
    this.inputTarget.value = ""
    this.search("")
  }

  stopPropagation(e) {
    e.stopPropagation()
  }

  openModal(e) {
    let tag = e.target.closest(".tag")
    let id = tag.dataset.tagId
    let name = tag.dataset.tagName

    let form = this.editModal.querySelector("#edit-tag-modal-form")
    let deleteForm = this.editModal.querySelector("#edit-tag-modal-delete")

    form.action = `/tags/${id}`
    deleteForm.action = `/tags/${id}`

    let input = this.editModal.querySelector("#edit-tag-modal-name")
    input.value = name
    this.editModal.classList.remove("hidden")
  }

  closeModal() {
    this.editModal.classList.add("hidden")
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="account--user-role"
export default class extends Controller {
  static values = {
    userId: Number,
    url: String,
  }

  selectRole(e) {
    fetch(this.urlValue, {
      method: "POST",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.head.querySelector(`meta[name="csrf-token"]`)?.getAttribute("content"),
      },
      body: JSON.stringify({
        role: e.currentTarget.dataset.role,
      }),
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["preview", "input"]

  connect() {}

  inputChanged(e) {
    let value = e.currentTarget.value
    value = this.validateValue(value)
    e.currentTarget.value = value

    this.previewTarget.style.backgroundColor = value
  }

  pickerChanged(e) {
    let value = e.currentTarget.value
    this.previewTarget.style.backgroundColor = value
    this.inputTarget.value = value.toUpperCase()
  }

  validateValue(value) {
    if (value === "") {
      return "#"
    } else {
      return value
    }
  }
}

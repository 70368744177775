import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="editor--toggle-dark-mode"
export default class extends Controller {
  connect() {
    this.workspace = document.querySelector('#workspace')
    document.addEventListener('darkModeFormToggled', this.toggleClass.bind(this))
  }

  toggleClass(e){
    if(this.workspace && e.detail){
      e.detail.opened ? this.workspace.classList.add('dark-mode') : this.workspace.classList.remove('dark-mode')
    } 
  }
}


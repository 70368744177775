import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="editor--multi-slider--universal-style-form"
export default class extends Controller {
  connect() {
    this.styleId = this.element.dataset.projectId
    document.addEventListener('universalFullscreenChanged', this.handleUniversalFullscreenChanged.bind(this))
    document.addEventListener('universalModalPositionChanged', this.handleUniversalModalPositionChanged.bind(this))
  }

  handleUniversalFullscreenChanged(e){
    let inputsSection = document.querySelector("#universal_modal_inputs")  
    if(e.detail.value === "false"){
      this.show(inputsSection)
    } else {
      this.hide(inputsSection) 
    }

    // Apply changes
    let targets = document.querySelectorAll(`.multi-slider-${this.styleId} .inapp`)
    if (e.detail.value === "false") {
      targets.forEach(target => {
        let modalHeight = document.querySelector('#multi_slider_style_modal_height')
        let modalWidth = document.querySelector('#multi_slider_style_modal_width')
        target.style["height"] = modalHeight.value
        target.style["width"] = modalWidth.value
      })
    } else {
      targets.forEach(target => {
        target.style["height"] = "100%"
        target.style["width"] = "100%"
      })
    }
  }

  handleUniversalModalPositionChanged(e) {
    let targets = document.querySelectorAll(`.multi-slider-${this.styleId} .inapp-wrapper`)
    targets.forEach(target => {
      target.style["justify-content"] = e.detail.value
    })
  }

  hide(node) {
    node.classList.add("hidden")
  }

  show(node) {
    node.classList.remove("hidden")
  }
}

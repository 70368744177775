import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab"]
  static classes = ["selected"]
  static values = {
    defaultOpened: Number,
  }

  connect() {
    this.selectTab(this.tabTargets[this.defaultOpenedValue])
  }

  selectTab(target) {
    this.tabTargets.forEach((tab) => {
      tab.classList.remove(...this.selectedClasses)
      if (tab.dataset.contentTarget) {
        let content = document.querySelector(tab.dataset.contentTarget)
        content.classList.add("hidden")
      }
    })
    target.classList.add(...this.selectedClasses)
    if (target.dataset.contentTarget) {
      let content = document.querySelector(target.dataset.contentTarget)
      content.classList.remove("hidden")
    }
  }

  handleSelection(event) {
    this.selectTab(event.currentTarget)
  }
}

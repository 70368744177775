import { Controller } from "@hotwired/stimulus"
import sortable from "html5sortable/dist/html5sortable.es.js"

export default class extends Controller {
  static targets = ["card", "form"]
  static values = {
    id: String,
    url: String,
  }

  connect() {
    sortable(".sort-carousel-cards", {
      handle: ".handle",
      placeholderClass: "slide-placholder",
      items: `.carousel-${this.idValue}-card`,
    })

    sortable(".sort-carousel-cards").forEach((sortableEl) => {
      sortableEl.addEventListener("sortupdate", (e) => {
        this.updateSlidesOrder(e.detail.destination.items)
      })
    })
  }

  selectSlide(e) {
    let id = e.currentTarget.dataset.id
    this.hideAllForms()
    this.unselectAllCards()
    this.showForm(id)
    this.selectCard(id)
  }

  hideAllForms() {
    this.formTargets.forEach((form) => {
      form.classList.add("hidden")
    })
  }

  unselectAllCards() {
    this.cardTargets.forEach((card) => {
      card.classList.remove("border-4", "border-indigo-500")
      card.classList.add("border-2", "border-gray-300")
    })
  }

  showForm(id) {
    let form = document.querySelector(`.carousel-slide-${id}-form`)
    if (form) {
      form.classList.remove("hidden")
    }
  }

  selectCard(id) {
    let card = document.querySelector(`.carousel-slide-${id}`)
    if (card) {
      card.classList.remove("border-2", "border-gray-300")
      card.classList.add("border-4", "border-indigo-500")
    }
  }

  updateSlidesOrder(items) {
    let selectedDeviceId = document.querySelector('input[name="selected_device"]').value

    var slides = []
    items.forEach((item, i) => {
      slides[i] = item.dataset.slideId
    })
    fetch(this.urlValue, {
      method: "PUT",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.head.querySelector(`meta[name="csrf-token"]`)?.getAttribute("content"),
      },
      body: JSON.stringify({
        slides: slides,
        selected_device: selectedDeviceId
      }),
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }
}

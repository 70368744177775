import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
  static classes = ["hidden"]
  static targets = ["content"]

  /*
  Show or Hide specific element.
  Target element ID should be added as data-element-id
*/

  toggleAnimated(event) {
    event.preventDefault()
    if (this.contentTarget.classList.contains(this.hiddenClass)) {
      enter(this.contentTarget)
    } else {
      leave(this.contentTarget)
    }
  }

  close(e) {
    // Ignore event if clicked within element
    if (this.element === e.target || this.element.contains(e.target)) return
    leave(this.contentTarget)
  }
}

import ApplicationController from "../../../../javascript/controllers/application_controller"

export default class extends ApplicationController {
  static targets = ['datetime', 'timezone']
  static values = {
    id: Number
  }

  connect() {
    super.connect()
    document.addEventListener('countdownLabelsPositionChanged', this.handleLabelsPositionChanged.bind(this))
  }

  datetimeChanged(e) {
    let datetimeValue = this.datetimeTarget.value
    datetimeValue = datetimeValue.replace('T', ' ')

    let timezoneSelected = this.timezoneTarget.selectedIndex
    let timezoneOffset = this.timezoneTarget.options[timezoneSelected].value
    let date = new Date(datetimeValue + " UTC" + timezoneOffset)
    let timestamp = date.getTime()

    let countdown = document.querySelector(`.countdown-${this.idValue}`)
    countdown.dataset.targetDateTime = timestamp

    if (timestamp) {
      this.updateElementField(
        'CountdownElement',
        this.idValue,
        'target_date_time',
        timestamp / 1000
      )
    }
  }

  handleLabelsPositionChanged(e) {
    let countdownForm = e.detail.element.closest('form')
    if (countdownForm) {
      let id = countdownForm.getAttribute('data-components--forms--countdown-id-value')
      let targets = document.querySelectorAll(`.countdown-${id} .countdown-wrapper .unit`)
      targets.forEach(target => {
        target.style["flex-direction"] = e.detail.value
        target.style["align-items"] = e.detail.value == "column" ? "center" : "baseline"
      })
    }
  }
}

import Siema from "./siema"

export class SiemaWithDots extends Siema {
  addDots() {
    this.dots = document.createElement("div")
    this.dots.classList.add("siema-dots")
    for (let t = 0; t < this.innerElements.length; t++) {
      const t = document.createElement("div")
      t.classList.add("siema-dots__item"), this.dots.appendChild(t)
    }
    this.selector.parentNode.insertBefore(this.dots, this.selector.nextSibling)
  }
  updateDots() {
    for (let t = 0; t < this.dots.querySelectorAll("div").length; t++) {
      const e = this.currentSlide === t ? "add" : "remove"
      this.dots.querySelectorAll("div")[t].classList[e]("siema-dots__item--active")
    }
  }
  destroy(t) {
    super.destroy(t), this.dots.remove()
  }
}

export let initializeCarousels = (selector) => {
  document.querySelectorAll(selector).forEach((carousel) => {
    let car = new SiemaWithDots({
      selector: carousel,
      onInit: function () {
        this.addDots()
        this.updateDots()
      },
      onChange: function () {
        this.updateDots()
      },
    })
    let destroy = () => { car.destroy() }
    let resizeHandler = () => {
      setTimeout(() => {
        car.resizeHandler()
      }, 100)
    }
    document.addEventListener("initializeCarousels", destroy)
    document.addEventListener("rerenderCarousels", resizeHandler)
  })
}

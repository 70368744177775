import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="registration"
export default class extends Controller {
  static targets = ["currency", "checkbox", "button"]

  checkValidation() {
    let terms = this.checkboxTarget
    let currency = this.currencyTarget
    if (terms.checked && (currency.value !== "")) {
      this.enableButton()
    } else {
      this.disableButton()
    }
  }

  checkboxUpdated(e) {
    if (e.target.checked) {
      this.enableButton()
    } else {
      this.disableButton()
    }
  }

  updateSelectedCountryText(event) {
    const selectedOption = event.target.options[event.target.selectedIndex];
    if (selectedOption) {
      const countryCode = selectedOption.textContent.match(/\+(\d+)/);
      if (countryCode) {
        selectedOption.textContent = "+" + countryCode[1];
      }
    }
  }

  enableButton() {
    this.buttonTarget.removeAttribute("disabled")
    this.buttonTarget.classList.remove("cursor-not-allowed")
  }
  disableButton() {
    this.buttonTarget.setAttribute("disabled", "true")
    this.buttonTarget.classList.add("cursor-not-allowed")
  }
}

import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="editor--name-section"
export default class extends Controller {
  clear() {
    if (this.element.value == "Untitled") {
      this.element.value = ""
    }
  }

  submit(e) {
    e.currentTarget.form.requestSubmit()
  }
}
